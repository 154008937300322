<template>
  <div class="Page-Wrapper">
    <!-- <SortingWithFiltersPreset
      class="SFPRestyle"
      title="Администрирование пользователей события"
      :onlySearch="true"
      searchPlaceholder="Поиск события"
      :searchAttribures="['name', 'surname']"
      :originalArray="Mentors.original"
      :setNewArrayCallback="handleArrayUpdate"
      :sortMethodCallback="handleSort"
      :filtersButtons="FiltersButtons"
      :sortOptions="[{ text: 'Сортировка по умолчанию', val: 'default' }]"
      :filterButtonModeServer="true"
      :callbackForFilterButton="handleFilter"
      :postFiltersDataChanges="handleFilterChanges"
      :setFiltersData="Filters"
      :key="'SortingFiltersSearch-' + mentors_hash"
    >
      <template v-slot:content>
        <div class="SearchButtonsWrapper">
          <div class="SearchButtons">
            <OUIButtonPrimary
              @click="openEditModal(null)"
              ButtonText="Добавить пользователя"
              :size="'medium'"
            />
          </div>
        </div>
      </template>
    </SortingWithFiltersPreset> -->

    <OUIButtonPrimary
      @click="openEditModal(null)"
      ButtonText="Добавить пользователя"
      :size="'medium'"
    />

    <div
      v-if="EventData?.event_users != null"
      class="MentorsList"
      :key="`mentors_filtered_hash`"
    >
      <div class="GridEvents noselect">
        <div class="GridEl">
          <span class="MentorFIO">Фамилия Имя</span>
          <span class="MentorFIO">Email</span>
          <span class="MentorFIO">Телефон</span>
          <span class="MentorFIO">ТГ ник</span>
          <span class="MentorFIO">Действие</span>
        </div>

        <div
          class="GridEl"
          v-for="(mentor, mindx) in EventData?.event_users"
          :key="`mentor-${mindx}-${mentor?.user?.id}`"
        >
          <span class="MentorFIO"
            >{{ mentor?.user?.surname }} {{ mentor?.user?.name }}</span
          >

          <span class="MentorFIO">{{ mentor?.user?.email }}</span>
          <span class="MentorFIO">{{ mentor?.user?.tel }}</span>
          <span class="MentorFIO">{{ mentor?.user?.tg_nick }}</span>

          <OUIButtonTertary
            @click="handleRemoveModal(mentor?.user?.id)"
            ButtonText="Удалить"
          />
        </div>
      </div>
    </div>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="EditModal.show"
      :hideModal="closeEditModal"
      :key="EditModal.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="EditModal.show">
          <span class="Offer-Modal-Title">Добавление пользователей</span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <el-autocomplete
                v-model="EditModal.search"
                :fetch-suggestions="suggestion"
                :trigger-on-focus="false"
                clearable
                style="width: 100%"
                placeholder="Поиск по названию"
                @select="(item) => (EditModal.id = item.id)"
                @input="
                  () => {
                    EditModal.id = null;
                  }
                "
              />
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span class="Offer-Button-Prev" @click="closeEditModal"
              >Отмена</span
            >
            <span class="Offer-Button-Next" @click="createOrUpdateMentor"
              >Добавить</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import OUIButtonPrimary from "@/components/OctaUI/Buttons/primary.vue";
import OUIButtonPrimaryOutline from "@/components/OctaUI/Buttons/primary_outline.vue";
import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

import { QuillEditor } from "@vueup/vue-quill";

import {
  GetEventWithUsers,
  GetAllUsers,
  AddUser,
  RemoveUser,
} from "@/api/events.js";

import { ElMessage, ElMessageBox } from "element-plus";

import Fuse from "fuse.js";

export default {
  data() {
    return {
      Filters: {
        button: null,
        search: "",
        sorting: "default",
      },
      FiltersButtons: [],

      Mentors: {
        original: [],
        filtered: [],
      },

      EditModal: {
        show: false,
        id: null,
        search: "",
      },

      mentorForDelete: null,

      SectionsList: [],

      EventID: null,
      EventData: null,

      UsersList: [],
      Tick: 0,
      LoadUsersPerTick: 200,
    };
  },
  components: {
    SortingWithFiltersPreset,
    OUIButtonPrimary,
    OUIButtonPrimaryOutline,
    NewStyledModal,
    OUIButtonTertary,
    QuillEditor,
  },
  computed: {},
  async created() {
    if (this.$route.query?.id != null) {
      this.EventID = parseInt(this.$route.query?.id);
      try {
        let mentorResp = await GetEventWithUsers({
          id: this.EventID,
        });
        this.EventData = mentorResp.data;
      } catch (e) {
        alert("Произошла ошибка на стороне сервера при получении ментора");
        this.$router.push("/admin/events");
      }
    } else {
      this.$router.push("/admin/events");
    }

    ElMessage({
      message: "Начата загрузка пользователей",
      type: "success",
    });

    await this.getUsersList();
  },
  methods: {
    suggestion(query, cb) {
      if (this.EditModal.search === "") {
        return cb(
          this.UsersList.map((x) => {
            return {
              ...x,
              value: `${x.surname} ${x.name} (${x.email}, ${x.tel})`,
            };
          })
        );
      }

      let result = [];

      const options = {
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        threshold: 0.25,
        keys: ["name", "surname", "email", "tel", "tg_nick"], // Поля, по которым будет производиться поиск
      };

      // Инициализация экземпляра fuse
      const fuse = new Fuse(this.UsersList, options);

      // Выполнение поиска
      result = fuse.search(this.EditModal.search);
      result = result.map((x) => x.item);

      return cb(
        result.map((x) => {
          return {
            ...x,
            value: `${x.surname} ${x.name} (${x.email}, ${x.tel})`,
          };
        })
      );
    },

    async getUsersList() {
      this.Tick++;

      let _users_list_resp = await GetAllUsers({
        tick: this.Tick,
        usersCount: this.LoadUsersPerTick,
      });

      let RawData = _users_list_resp.data.filter((x) => {
        let find = this.EventData.event_users.find((i) => i.user?.id === x.id);

        if (find != null) {
          return false;
        }

        return true;
      });

      this.UsersList = this.UsersList.concat(RawData);

      if (_users_list_resp.data.length < this.LoadUsersPerTick) {
        ElMessage({
          message: "Список пользователей успешно загружен",
          type: "success",
        });
      } else {
        setTimeout(async () => {
          await this.getUsersList();
        }, 500);
      }
    },

    formatDate(date) {
      date = new Date(date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },

    handleEditorEditChanges(delta) {
      this.EditModal.desc = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.EditModal.desc);
    },

    async initialization() {
      try {
        let mentorResp = await GetEventWithUsers({
          id: this.EventID,
        });
        this.EventData = mentorResp.data;

        this.UsersList = this.UsersList.filter((x) => {
          let find = this.EventData.event_users.find(
            (i) => i.user?.id === x.id
          );

          if (find != null) {
            return false;
          }

          return true;
        });
      } catch (e) {
        alert("Произошла ошибка на стороне сервера при получении ментора");
        this.$router.push("/admin/events");
      }
    },

    getSectionsForMentor(MentorDirections) {
      return MentorDirections == null
        ? []
        : MentorDirections.map((x) => x.name);
    },

    openEditModal() {
      this.EditModal.show = true;
    },
    closeEditModal() {
      this.EditModal = {
        show: false,
        id: null,
        search: "",
      };
    },
    async createOrUpdateMentor() {
      if (this.EditModal.id != null) {
        try {
          let resp = await AddUser({
            userID: this.EditModal.id,
            eventID: this.EventID,
          });

          ElMessage({
            message: "Пользователь успешно добавлен!",
            type: "success",
          });
          this.closeEditModal();
        } catch (e) {
          ElMessage.error("Произошла ошибка на стороне сервера");
        }
      } else {
        ElMessage.error("Пользователь не выбран!");
        return 0;
      }

      await this.initialization();
    },

    handleArrayUpdate(UpdatedArray) {
      this.Mentors.filtered = UpdatedArray;
    },
    handleSort(SortName, Array) {
      return Array;
    },
    handleFilter(val) {
      this.Filters.button = val;
    },
    handleFilterChanges(obj) {
      this.Filters.button = obj.button;
      this.Filters.search = obj.search;
      this.Filters.sorting = obj.sorting;
    },

    async handleRemoveModal(mentorID) {
      ElMessageBox.confirm(
        "Вы действительно хотите удалить пользователя?"
      ).then(async () => {
        try {
          await RemoveUser({
            userID: mentorID,
            eventID: this.EventID,
          });

          ElMessage({
            message: "Пользователь удален!",
            type: "success",
          });

          await this.initialization();
        } catch (e) {
          ElMessage.error("Произошла ошибка на стороне сервера");
        }
      });
    },
  },
};
</script>

<style scoped>
.GridEvents {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
}
.GridEl {
  position: relative;
  display: grid;

  padding: 12px;

  width: 100%;
  height: fit-content;

  grid-template-columns: repeat(5, 1fr);

  border-bottom: 1px solid gray;
}

.Page-Wrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-top: 100px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  max-width: 1200px;
  min-height: 100svh;

  padding-right: 15px;

  overflow: auto;
}

.SearchButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: fit-content;
  height: fit-content;
}
.SearchButtonsWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: fit-content;
  height: fit-content;
}

.MentorsList {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}
.Mentor {
  position: relative;
  display: flex;

  padding: 20px;

  min-width: 0;
  width: calc((100% - (24px * 3)) / 4);
  max-width: 100%;

  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  flex-basis: auto;

  transition: 0.25s;

  background: url("./../../../assets/img/Admin/LogoBG.png");
  background-position: 98% 0%;
  background-size: contain;
  background-repeat: no-repeat;

  border-radius: var(--o-s-global-border-radius);
  background-color: #1e1b4a;
}
.Mentor:hover {
  background-color: #27235c;
}
.MentorFIO {
  position: relative;
  display: block;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;

  word-break: keep-all;
  word-wrap: break-word;

  color: #ffffffe5;
}
.MentorDirections {
  position: relative;
  display: flex;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 14px;
  flex-wrap: wrap;
}
.MentorSection {
  position: relative;
  display: block;

  padding: 4px 8px;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 15.85px;
  letter-spacing: 0%;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  background-color: #656bff33;
}

.Buttons {
  position: relative;
  display: flex;

  margin-top: auto;
  padding-top: 12px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;
}

.MultiDirectionsTip {
  position: relative;
  display: block;
  margin-top: 12px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 120px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>
